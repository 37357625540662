import { useEffect, useState } from "react";
import { useMUD } from "./MUDContext";
import { Duration } from "luxon";
import { getBalance } from "viem/actions";

function TransactionView({ id }: { id: string }) {
  const {
    network: { localStore, walletClient },
  } = useMUD();

  const blockExplorerUrl =
    walletClient.chain.blockExplorers &&
    walletClient.chain.blockExplorers.default.url;

  const transaction = localStore((state) => {
    return state.transactions[id];
  });

  const submissionTime =
    (transaction?.acceptedTimestamp ?? 0n) -
    (transaction?.startTimestamp ?? 0n);

  const confirmationTime =
    (transaction?.completedTimestamp ?? 0n) -
    (transaction?.acceptedTimestamp ?? 0n);

  const stateUpdatedTime =
    (transaction?.stateUpdateTimestamp ?? 0n) -
    (transaction?.acceptedTimestamp ?? 0n);

  const totalTime = submissionTime + stateUpdatedTime;

  const bar = (
    <div style={{ width: "100%", height: "1rem", display: "flex" }}>
      {Boolean(
        transaction?.acceptedTimestamp && transaction?.startTimestamp
      ) && (
        <div
          style={{
            width: `${(submissionTime / 100n) * 10n}px`,
            minWidth: "72px",
            backgroundColor: "lightblue",
            height: "100%",
            textAlign: "center",
          }}
        >
          {Duration.fromDurationLike({
            milliseconds: Number(submissionTime),
            // display ms
          }).toFormat("mm:ss:SSS")}
        </div>
      )}
      <div>
        {Boolean(
          transaction?.completedTimestamp && transaction?.acceptedTimestamp
        ) && (
          <div
            style={{
              width: `${(confirmationTime / 100n) * 10n}px`,
              minWidth: "72px",
              backgroundColor: "lightgreen",
              height: "100%",
              textAlign: "center",
            }}
          >
            {Duration.fromDurationLike({
              milliseconds: Number(confirmationTime),
              // display ms
            }).toFormat("mm:ss:SSS")}
          </div>
        )}
        {Boolean(
          transaction?.stateUpdateTimestamp && transaction?.acceptedTimestamp
        ) && (
          <div
            style={{
              width: `${(stateUpdatedTime / 100n) * 10n}px`,
              minWidth: "72px",
              backgroundColor: "yellow",
              height: "100%",
              textAlign: "center",
            }}
          >
            {Duration.fromDurationLike({
              milliseconds: Number(stateUpdatedTime),
              // display ms
            }).toFormat("mm:ss:SSS")}
          </div>
        )}
      </div>
    </div>
  );

  return (
    <div
      style={{
        width: "360px",
        borderRadius: "4px",
        border: "3px black solid",
        padding: "4px",
        marginBottom: "4px",
      }}
    >
      <div style={{ display: "flex" }}>
        <div style={{ fontWeight: "bold" }}>{transaction.systemCall}</div>
        <div style={{ flex: 1 }} />
        <div style={{ marginLeft: "20px" }}>
          Sumitted Block:{" "}
          {transaction.submittedBlock
            ? transaction.submittedBlock.toString()
            : ""}
        </div>
        <div>
          Completed Block:{" "}
          {transaction.completedBlock
            ? transaction.completedBlock.toString()
            : ""}
        </div>
      </div>
      <div>
        Hash:{" "}
        <a
          href={blockExplorerUrl + "/tx/" + transaction.hash}
          target="_blank"
          rel="noopener noreferrer"
        >
          {transaction?.hash?.slice(0, 8) ?? ""}
        </a>
      </div>
      <div>Status: {transaction.status}</div>
      {bar}
      <div>
        <br />
        Total Time:{" "}
        {totalTime > 0n
          ? Duration.fromDurationLike({
              milliseconds: Number(totalTime),
              // display ms
            }).toFormat("mm:ss:SSS")
          : 0}
      </div>
    </div>
  );
}

export function TransactionList() {
  const {
    network: { localStore, latestBlock$, walletClient },
  } = useMUD();

  const [balance, setBalance] = useState(0n);

  const [latestBlock, setLatestBlock] = useState(0n);
  useEffect(() => {
    const subscription = latestBlock$.subscribe((block) => {
      if (!block.number) return;

      setLatestBlock(block.number);
      const b = getBalance(walletClient, {
        address: walletClient.account?.address ?? "",
      });
      b.then((b) => {
        setBalance(b);
      });
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [latestBlock$, walletClient]);

  const transactions = localStore((state) => {
    return state.transactions;
  });

  return (
    <div
      style={{
        border: "1px solid black",
        padding: "1rem",
        maxHeight: "100%",
        overflowY: "auto",
      }}
    >
      <h2>Block: {latestBlock.toString()}</h2>
      <p>
        Chain: {walletClient.chain.name} {walletClient.chain.id}
      </p>
      <p>Account: {walletClient.account?.address}</p>
      <p>
        (Use the <strong>?privateKey=</strong> URL param to provide your own
        private key for Redstone mainnet)
      </p>
      <p>Balance: {balance.toString()}</p>
      <div>
        Color Legend:
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginBottom: "1rem",
          }}
        >
          <div style={{ backgroundColor: "lightblue", width: "1rem" }} />
          <div>Submission</div>
          <div style={{ backgroundColor: "lightgreen", width: "1rem" }} />
          <div>Viem confirmation</div>
          <div style={{ backgroundColor: "yellow", width: "1rem" }} />
          <div>MUD confirmation</div>
        </div>
      </div>
      {Object.keys(transactions)
        .reverse()
        .map((id) => {
          return <TransactionView key={id} id={id} />;
        })}
    </div>
  );
}
