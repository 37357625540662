import React from "react";
import { supportedChains } from "./mud/supportedChains";

export const ChainList = () => {
  return (
    <div>
      {supportedChains.map((chainDetails) => (
        <p key={chainDetails.id}>
          <a href={`/?chainId=${chainDetails.id}`}>{chainDetails.name}</a>
        </p>
      ))}
    </div>
  );
};
