import { TransactionData, setupNetwork } from "./mud/setupNetwork";
import { Hex } from "viem";
import { uuid } from "@latticexyz/utils";

type ContractType = Awaited<ReturnType<typeof setupNetwork>>["worldContract"];

type CreateSystemExecutorArgs = {
  worldContract: ContractType;
  network: Awaited<ReturnType<typeof setupNetwork>>;
};

type SystemExecutorArgs<T extends keyof ContractType["write"]> = {
  // The literal system that will be called on the Contract
  systemCall: T;
  // Human readable ID for use in A) analytics and B) determining gas estimate from past txs
  // This is needed because callFrom and batchCall can't be used as unique identifiers but are the
  // top level system names.
  systemId?: string;
  args: Parameters<ContractType["write"][T]>;
  onRevertCallback?: () => Promise<void>;
};

export const createSystemExecutor = ({
  worldContract,
  network,
}: CreateSystemExecutorArgs) => {
  let latestBlock = 0n;
  network.latestBlock$.subscribe((block) => {
    if (!block.number) return;

    latestBlock = block.number;
  });

  const executeSystem = async <T extends keyof ContractType["write"]>({
    systemCall,
    args,
  }: SystemExecutorArgs<T>): Promise<Hex | undefined> => {
    const actionId = uuid() as string;
    const contract = worldContract;

    const useLocalStore = network.localStore;
    useLocalStore.setState((state) => {
      state.transactions[actionId] = {
        id: actionId,
        systemCall,
        status: "pending",
        gasEstimate: null,
        gasPrice: null,
        hash: null,
        error: null,
        submittedBlock: null,
        completedBlock: null,

        startTimestamp: null,
        acceptedTimestamp: null,
        completedTimestamp: null,
        stateUpdateTimestamp: null,
      };

      return state;
    });

    const updateTx = (update: Partial<TransactionData>) => {
      useLocalStore.setState((state) => ({
        ...state,
        transactions: {
          ...state.transactions,
          [actionId]: {
            ...state.transactions[actionId],
            ...update,
          },
        },
      }));
    };

    updateTx({
      startTimestamp: BigInt(Date.now()),
    });

    const systemArgs = args[0];
    const txOptions = args[1] || {};

    let txHash: Hex | undefined;

    try {
      const txPromise = (contract.write[systemCall] as any)(
        systemArgs,
        txOptions
      ) as Promise<Hex>;

      const txHash = await txPromise;
      updateTx({
        status: "submitted",
        submittedBlock: latestBlock,
        acceptedTimestamp: BigInt(Date.now()),
        hash: txHash,
      });

      // Wait for tx using MUD
      network.waitForTransaction(txHash).then(() => {
        updateTx({ stateUpdateTimestamp: BigInt(Date.now()) });
      });

      // Wait for tx using viem
      const receipt = await network.publicClient.waitForTransactionReceipt({
        hash: txHash,
        confirmations: 0,
      });
      console.log("got receipt", receipt.blockNumber);
      updateTx({
        status: "completed",
        completedTimestamp: BigInt(Date.now()),
        completedBlock: receipt.blockNumber,
      });
    } catch (e) {
      updateTx({
        status: "reverted",
        error: (e as Error)
          .toString()
          .replaceAll(",", "")
          .replaceAll("\n", " "),
        completedTimestamp: BigInt(Date.now()),
      });
    }

    return txHash;
  };

  return {
    executeSystem,
  };
};
